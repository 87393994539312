// Dvir related config
import {getEnvironmentConfig} from './dvir/config'
import {getChatConfig} from '@peoplenet/tfm-shared/chat/config'
import {getEldConfig} from '@peoplenet/tfm-shared/eldUi/config'
import {getMioeConfig} from '@peoplenet/tfm-shared/mioeUi/config'
import {getOrgManagementConfig} from '@peoplenet/tfm-shared/orgManagement/config'
import {getRemoteLogoutConfig} from '@peoplenet/tfm-shared/remoteLogout/config'

window.dvirEnvUrl = getEnvironmentConfig()
window.chatEnvUrl = getChatConfig()
window.eldEnvUrl = getEldConfig()
window.mioeEnvUrl = getMioeConfig()
window.orgManagementUrl = getOrgManagementConfig()
window.remoteLogoutEnvUrl = getRemoteLogoutConfig()

import('./bootstrap')
